import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <h3>About Gembe & Watson</h3>
    <p>Gembe & Watson is a private estate established in 2009 which funds the public, charitable and private activities of its owner and family.</p>
    <p>Revenue from Gembe & Watson forms a vital component of the owner's income and is held seperately from that of other ventures.</p>
    <h3>History of the Estate</h3>
    <p>The Estate was created in 2009 by its owners for the advancement of their family and interests so that they might thrive well beyond their lifetimes.</p>
  </Layout>
)

export default AboutPage
